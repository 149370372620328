import { getAverageReturns } from "./get-average-returns"

export const getAverageReturnSeries = (
  initialInvestment,
  monthlySavings,
  averageRate,
  lowerRate,
  upperRate,
  time,
  exchangeRate
) => {
  let resultObjArr = []
  let averageReturns = getAverageReturns(
    monthlySavings,
    initialInvestment,
    1,
    averageRate,
    lowerRate,
    upperRate,
    time,
    exchangeRate
  )

  for (let t = 0; t < time; t++) {
    let obj = {
      averageReturnSeries: [],
      returnRangeSeries: [],
      totalContributions: 0,
      investMentYear: 0,
    }

    let investYear = new Date().getFullYear() + t
    obj.averageReturnSeries = [investYear, averageReturns[t].averageReturn]
    obj.returnRangeSeries = [
      investYear,
      averageReturns[t].lowerReturn,
      averageReturns[t].upperReturn,
    ]
    obj.totalContributions = monthlySavings * ((t + 1) * 12)
    obj.investMentYear = investYear
    resultObjArr[t] = obj
  }
  return resultObjArr
}
