import React, { useEffect } from "react"
import Meta from "../utilities/seo"
import Footer from "../components/layout/footer"
import Header from "../components/layout/header"
import Planning from "../components/home/planning/container"
import Main from "../components/layout/main"
import Splash from "../components/home/splash/container"
import Saving from "../components/home/saving/container"
import Investment from "../components/home/investment/container"
import Process from "../components/home/process/container"
import Support from "../components/home/support/container"

const Page = () => {
  return (
    <>
      <Meta
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/a_hflip/v1633502840/shutterstock_1750668944_qaeh93.jpg",
          height: "1280",
          width: "853",
        }}
      />
      <Main>
        <Header />
        <Splash />
        <Process />
        <Planning />
        <Saving />
        <Investment />
        {/* <Support /> */}
        <Footer />
      </Main>
    </>
  )
}
export default Page
