import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { vw } from "../../../utilities/pxtovw"
import Chevron from "../../shared/chevron"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Pattern from "../../shared/pattern"
import Icon from "../../shared/icon"
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "../../../utilities/context"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Regulators from "./regulators"

const Box = styled.div`
  flex: 1;
  margin-top: ${vw(56)};
  margin-right: ${vw(108)};
  position: relative;
  height: ${vw(480)};

  .xeno-chevy {
    width: ${vw(207)};
    height: ${vw(338)};
    right: -16%;
    top: 5rem;
    z-index: 10;
  }

  .xn-pattern {
    position: absolute;
    top: 0;
    height: ${vw(42)};
    width: ${vw(108)};
    right: ${vw(-108)};
  }

  @media (max-width: 766px) {
    margin-top: 0;
    height: 60vw;

    .xeno-chevy {
      width: ${vw(207)};
      height: ${vw(338)};
      right: 0%;
      top: 0;
    }

    .xn-pattern {
      top: 0;
      height: ${vw(96)};
      width: ${vw(108)};
    }
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 1.5rem;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    transform: scale3d(1.02, 1.02, 1.02);
  }
`

const Button = styled.button`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 2vw;

  &:hover {
    &:after {
      opacity: 0.2;
    }

    svg {
      opacity: 0;
    }
  }

  svg {
    width: 7.5%;
    height: 7.5%;
    position: relative;
    z-index: 1;
    transition: opacity 0.3s;
  }

  path {
    transition: fill 0.3s ease;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    opacity: 0;
    background-color: #000;
  }
`

const Ele = () => {
  const hero = useRef(null)
  const loop = useRef(null)
  const dispatch = useGlobalDispatchContext()
  const { video } = useGlobalStateContext()

  const logEvent = label => {
    if (typeof window !== "undefined") {
      trackCustomEvent({
        category: label,
        action: "Click",
        label: "Home - Splash",
        value: 1,
      })
    }
  }

  //pause or play looping video
  // useEffect(() => {
  //   video === "" ? loop.current.play() : loop.current.pause()
  // }, [video])

  const showVideo = () => {
    dispatch({
      type: "SET_VIDEO",
      src: "https://www.youtube.com/embed/wlatuXknR0o?modestbranding=1&autoplay=1",
    })
  }

  //show custom cursor on hover
  const onMouseEnter = () => {
    dispatch({
      type: "SET_CURSOR",
      cursor: "watch",
    })
  }

  const onMouseLeave = () => {
    dispatch({
      type: "SET_CURSOR",
      cursor: "",
    })
  }

  // useEffect(() => {
  //   let ref = hero.current
  //   ref.addEventListener("mouseenter", onMouseEnter)
  //   ref.addEventListener("mouseleave", onMouseLeave)
  //   return () => {
  //     ref.removeEventListener("mouseenter", onMouseEnter)
  //     ref.removeEventListener("mouseleave", onMouseLeave)
  //   }
  // }, [])

  // loop video
  const video_query = useStaticQuery(graphql`
    query VideoQuery {
      allPrismicHome {
        edges {
          node {
            data {
              splash_loop {
                url
              }
            }
          }
        }
      }
    }
  `)
  let video_url = video_query.allPrismicHome.edges[0].node.data.splash_loop.url

  return (
    <Box onClick={() => showVideo()}>
      {/* <Button ref={hero}>
        <Icon title="play" />
      </Button> */}
      <Chevron />
      <Image>
        {/* <video muted autoPlay loop ref={loop}>
          <source src={video_url} type="video/mp4" />
        </video> */}
        <StaticImage
          quality={100}
          src="https://res.cloudinary.com/xeno-technologies/image/upload/v1718352435/XENO_KE_Connect_Visual-Pack-03_nhseb8.jpg"
          alt="Woman seated at the beach"
          loading="eager"
          backgroundColor="#1a2b35"
        />
      </Image>
      <Pattern size="4" type="dot" />
      {/* <Regulators /> */}
    </Box>
  )
}
export default Ele
