import React from "react"
import styled, { css } from "styled-components"
import { rem } from "../../utilities/pxtorem"
import { vw } from "../../utilities/pxtovw"

const Box = styled.div`
  position: relative;
  color: var(--c-mint-100);
  h1,
  h2,
  blockquote p {
    font-family: var(--xn-font-title);
    font-style: normal;
    font-weight: bold;
    font-size: ${rem(36)};
    line-height: ${rem(46)};
    padding-bottom: ${vw(48)};

    span {
      color: var(--c-white);
    }

    &.small {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  &.large {
    h1 {
      font-size: ${rem(48)};
    }
  }

  blockquote {
    p {
      padding-bottom: 0;
    }

    cite {
      padding-top: 1rem;
      padding-bottom: ${rem(52)};
      text-align: right;
      display: block;
    }
  }

  ${props =>
    props.invert &&
    css`
      @media (prefers-color-scheme: light) {
        color: var(--c-blue-200);
        h1,
        h2,
        blockquote p {
          span {
            color: var(--c-green-200);
          }
        }
      }
    `}

  @media (max-width: 766px) {
    h1,
    h2,
    blockquote p {
      font-size: 2.25rem;
      padding-bottom: 2.5rem;
      line-height: 2.65rem;
    }
  }
`

const Ele = ({ children, invert, size }) => {
  return (
    <Box className={size} invert={invert}>
      {children}
    </Box>
  )
}

export default Ele
