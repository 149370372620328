import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Slider from "./slider"
import { vw } from "../../utilities/pxtovw"
import { rem } from "../../utilities/pxtorem"
import { useGlobalStateContext } from "../../utilities/context"
import { getAverageReturnSeries } from "./utils/get-average-return-series"
import { convertCurrency } from "../../utilities/convert-currency"
import wNumb from "wnumb"
import Results from "./results"
import Button from "../shared/button"
import Highcharts from "highcharts"
import highchartsMore from "highcharts/highcharts-more"
import HighchartsReact from "highcharts-react-official"
import Overline from "../shared/overline"
import GetStartedBtn from "../shared/get-started-btn"
import { logEvent } from "../../utilities/logevent"

const Box = styled.div`
  display: flex;
  flex-wrap: nowrap;

  .header {
    position: relative;
    padding-bottom: 0.75vw;
    span {
      font-size: 0.75rem;
      color: var(--c-mint-100);
      letter-spacing: 0;
      opacity: 1;
    }
  }

  .chart {
    padding-top: 3vw;
  }

  @media (max-width: 766px) {
    display: block;
  }
`

const Figures = styled.div`
  background-color: var(--c-blue-101);
  position: relative;
  width: 50%;
  @media (max-width: 766px) {
    width: auto;
  }
`

const Controls = styled.div`
  position: relative;
  padding: ${vw(36)};
  padding-bottom: 0;
  background-color: var(--c-mint-100);

  @media (max-width: 766px) {
    padding: 1.5rem;
  }
`

const Actions = styled.div`
  text-align: right;
  position: relative;
  padding-bottom: 5rem;

  @media (max-width: 766px) {
    padding-bottom: 0;
  }
`

const Disclaimer = styled.p`
  font-size: ${rem(10)};
  padding-top: 4vw;
  padding-bottom: 2vw;
  color: #343b47;

  @media (max-width: 766px) {
    padding: 1rem 0 2rem;
  }
`

const Graph = styled.div`
  width: 50%;
  background-color: #000;
  position: relative;
  padding-bottom: 2.25vw;

  .padd-on-mobile {
    padding: 1rem;
  }

  .action {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 766px) {
    width: auto;
    padding: 0;

    .action {
      padding-bottom: 1rem;
    }
  }
`

const Planner = () => {
  const { firstDeposit, monthlyDeposit, investmentYears, countryCode } =
    useGlobalStateContext()
  const [worstCase, setWorstCase] = useState()
  const [currency, setCurrency] = useState()
  const [bestCase, setBestCase] = useState()
  const [deposits, setDeposits] = useState()
  const [mostLikely, setMostLikely] = useState()
  const [profile, setProfile] = useState(null)
  const [chartOptions, setChartOptions] = useState()

  //get profile
  useEffect(() => {
    const getPortfolioAllocation = async () => {
      try {
        const res = await fetch(
          "https://bf.xn-io.com/leg/api/v1/guest/profiler/evaluate",
          {
            method: "POST",
            headers: new Headers({
              "Content-type": "application/x-www-form-urlencoded",
              tenantId: "UG",
            }),
            body: "goalCode=EMERGENCIES&riskScore=1",
          }
        )
        const data = await res
          .json()
          .then(response => {
            setProfile(response.profile)
          })
          .then(() => {
            computeResults()
          })
      } catch (error) {
        console.log(error)
      }
    }

    getPortfolioAllocation()
  }, [])

  useEffect(() => {
    switch (countryCode) {
      case "UG":
        setCurrency("UGX")
        break

      case "KE":
        setCurrency("KES")
        break

      default:
        break
    }
  })

  useEffect(() => {
    if (profile !== null) {
      triggerUpdate()
    }
  }, [profile])

  let computeResults = () => {
    if (profile !== null) {
      highchartsMore(Highcharts)

      let moneyFormat = wNumb({
        thousand: ",",
        prefix: currency + " ",
        decimals: 0,
      })

      let computeData = getAverageReturnSeries(
        parseInt(firstDeposit),
        parseInt(monthlyDeposit),
        profile.averageRate,
        profile.lowerRate,
        profile.upperRate,
        parseInt(investmentYears)
      )

      let finalYear = computeData[parseInt(investmentYears) - 1]

      setDeposits(
        moneyFormat.to(finalYear.totalContributions + parseInt(firstDeposit))
      )
      setBestCase(moneyFormat.to(finalYear.returnRangeSeries[2]))
      setWorstCase(moneyFormat.to(finalYear.returnRangeSeries[1]))
      setMostLikely(moneyFormat.to(finalYear.averageReturnSeries[1]))

      let investmentYearSeries = []
      let annualInvestmentSeries = []
      let averageReturnSeries = []
      let returnRangeSeries = []

      computeData.forEach(ele => {
        //investmnet year series data
        investmentYearSeries.push(ele.investMentYear)

        //annualInvestmentSeries data
        annualInvestmentSeries.push([
          ele.investMentYear,
          ele.totalContributions,
        ])

        //average returns data
        averageReturnSeries.push(ele.averageReturnSeries)

        //return range series data
        returnRangeSeries.push(ele.returnRangeSeries)
      })

      setChartOptions({
        title: {
          text: null,
        },
        chart: {
          plotBackgroundColor: null,
          backgroundColor: null,
          style: {
            fontFamily: "Arial",
            fontSize: "0.75rem",
          },
        },
        legend: {
          itemStyle: {
            fontWeight: "normal",
            color: "#fff",
          },
        },
        xAxis: {
          categories: investmentYearSeries,
          tickInterval: 5,
          gridLineColor: "#b5e4e7",
          lineColor: "#272B32",
          labels: {
            style: {
              color: "#c3c3c3",
            },
          },
          crosshair: {
            width: 1,
            color: "#fff",
            dashStyle: "shortdot",
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          gridLineColor: "#272B32",
          labels: {
            formatter: function () {
              return convertCurrency(this.value)
            },
            style: {
              color: "#c3c3c3",
            },
          },
          style: {
            color: "#718196",
          },
          min: 0,
        },
        tooltip: {
          crosshairs: true,
          shared: true,
          backgroundColor: "#00e2c1",
          formatter: function () {
            console.log(this.points[2].point.options)
            var c = "<b>" + this.x + "</b><br/><br/>"
            //deposits
            c +=
              "<span>Deposits: <b>" +
              convertCurrency(this.points[0].y) +
              "</b></span><br/>"

            //best case
            c +=
              "<span>Worst Case: <b>" +
              convertCurrency(this.points[2].point.options.low) +
              "</b></span><br/>"

            //most likely
            c +=
              "<span>Most likely: <b>" +
              convertCurrency(this.points[1].y) +
              "</b></span><br/>"

            //worst case
            c +=
              "<span>Best Case: <b>" +
              convertCurrency(this.points[2].point.options.high) +
              "</b></span><br/>"

            return c
          },
          borderWidth: 0,
        },
        credits: {
          enabled: false,
        },
        exporting: false,
        plotOptions: {
          series: {
            lineWidth: 1.5,
            fillColor: {
              linearGradient: [0, 0, 0, 400],
              stops: [
                [0, "#00E283"],
                [1, "#0017E2"],
              ],
            },
          },
        },
        series: [
          {
            name: "Deposits",
            data: annualInvestmentSeries,
            zIndex: 2,
            color: "#DE82FF",
            marker: {
              enabled: false,
            },
          },
          {
            name: "Most likely value",
            data: averageReturnSeries,
            zIndex: 1,
            color: "#FBE94A",
            marker: {
              enabled: false,
            },
          },
          {
            name: "Range",
            data: returnRangeSeries,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: Highcharts.getOptions().colors[0],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: { enabled: false },
          },
        ],
      })
    }
  }

  let triggerUpdate = () => {
    computeResults()
    logEvent("Home", "Simulate Plan")
  }

  return (
    <Box>
      <Figures>
        <Controls>
          {countryCode && countryCode === "UG" && (
            <>
              <Slider
                min={10000}
                max={10000000}
                start={1000000}
                step={5000}
                label="First Deposit"
                unit={currency}
              />
              <Slider
                min={10000}
                max={10000000}
                start={1000000}
                step={5000}
                label="Monthly Deposit"
                unit={currency}
              />
            </>
          )}

          {countryCode && countryCode === "KE" && (
            <>
              <Slider
                min={360}
                max={360000}
                start={36000}
                step={180}
                label="First Deposit"
                unit={currency}
              />
              <Slider
                min={360}
                max={360000}
                start={36000}
                step={180}
                label="Monthly Deposit"
                unit={currency}
              />
            </>
          )}

          <Actions>
            <Button
              dark
              pill
              label="Simulate"
              click={() => triggerUpdate()}
              id="simulate_planner_button"
            />
          </Actions>
        </Controls>
        <Results
          deposits={deposits}
          mostLikely={mostLikely}
          bestCase={bestCase}
          worstCase={worstCase}
        />
      </Figures>
      <Graph>
        <div className="header padd-on-mobile">
          {/* <Overline>{investmentYears} Year Performance</Overline> */}
          <Overline>Performance</Overline>
        </div>
        <div className="chart">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
        <Disclaimer className="padd-on-mobile">
          These are scenarios for {investmentYears} years not predictions. The
          value of your investments may go up as well as down. You may get back
          less than the amount you invested. Past performance is no guarantee of
          future performance. The purpose of the simulation is merely to
          illustrate the distribution of possible investment outcomes within a
          reasonable level of confidence.
        </Disclaimer>
        <div className="action padd-on-mobile">
          <GetStartedBtn
            title="Start Investing"
            id="start_investing_button__planner_section"
          />
        </div>
      </Graph>
    </Box>
  )
}
export default Planner
