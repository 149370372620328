import React from "react"
import styled from "styled-components"
import Overline from "../../shared/overline"
import Text from "../../shared/text"
import Title from "../../shared/title"
import ImageBtn from "../../shared/image-button"
import { vw } from "../../../utilities/pxtovw"
import { useGlobalDispatchContext } from "../../../utilities/context"
import { logEvent } from "../../../utilities/logevent"

const Box = styled.div`
  position: relative;
  padding-bottom: 3vw;

  a {
    color: var(--c-mint-100);
  }

  p {
    width: ${vw(434)};
  }

  @media (max-width: 766px) {
    padding: 2rem;
    padding-bottom: 3rem;
    p {
      width: auto;
    }
  }
`

const Intro = styled.div`
  width: ${vw(434)};

  @media (max-width: 766px) {
    width: auto;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 3rem;
  padding-top: 1rem;

  @media (max-width: 766px) {
    display: block;

    > div {
      padding-bottom: 2.5rem;
    }
  }
`

const Ele = () => {
  const dispatch = useGlobalDispatchContext()
  const showVideo = () => {
    dispatch({
      type: "SET_VIDEO",
      src: "https://www.youtube.com/embed/tWh31jNQrbM?modestbranding=1&autoplay=1",
    })
    logEvent("Home", "Watch Video")
  }

  return (
    <Box>
      <Intro>
        <Overline invert>Investment</Overline>
        <Title invert>
          <h2>
            See how investing could <span>grow</span> your savings
          </h2>
        </Title>
      </Intro>
      <Flex>
        <Text>
          <p>
            Run some simple investment scenarios for various amounts. Pro Tip:
            Download the XENO Investment App to run advanced personalized
            scenarios.
          </p>
        </Text>
        {/* <ImageBtn
          id="how_xeno_invests_link"
          label="How XENO invests"
          icon="play-filled"
          onClick={() => {
            showVideo()
          }}
          src="https://res.cloudinary.com/xeno-technologies/image/upload/c_fill,h_200,w_200/v1606847277/happy-ugandan-mother_suuckb.jpg"
        /> */}
      </Flex>
    </Box>
  )
}
export default Ele
