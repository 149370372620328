import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  position: absolute;
  right: 0;
  text-align: right;
  width: 50%;
  font-size: 0.625rem;
  bottom: -10%;
  line-height: 1.7;

  a {
    color: var(--c-mint-100);
    &:hover {
      opacity: 0.75;
    }

    @media (prefers-color-scheme: light) {
      color: var(--c-green-200);
    }
  }

  @media (max-width: 766px) {
    position: static;
    width: 100%;
    padding: 1.5rem 2rem;
    text-align: left;
  }
`

const Ele = () => {
  return (
    <Box>
      * In Uganda, Licensed and Regulated by{" "}
      <a
        href="https://cmauganda.co.ug/supervision/licensing-approvals/licensed-firms/"
        target="_blank"
        rel="noreferrer"
      >
        Capital Markets Authority
      </a>{" "}
      and{" "}
      <a
        href="https://urbra.go.ug/list-of-licensed-fund-managers/"
        rel="noreferrer"
        target="_blank"
      >
        Uganda Retirement Benefits Regulatory Authority
      </a>
    </Box>
  )
}
export default Ele
