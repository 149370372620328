export const getAnnualPortfolio = (
  monthlySavings,
  initialInvestment,
  startTime,
  investmentHorizon,
  portfolioReturn
) => {
  let timeHorInMonths = investmentHorizon * 12
  let monthlyReturn = portfolioReturn / 12
  let portfolioValue = initialInvestment
  for (let i = 0; i < timeHorInMonths; ++i) {
    portfolioValue = +portfolioValue * Math.pow(1 + +monthlyReturn, 1)
    portfolioValue = +portfolioValue + monthlySavings
  }

  return portfolioValue
}
